<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications></notifications>
    <sidebar-fixed-toggle-button />
    <side-bar
      :background-color="sidebarBackground"
      :short-title="$t('sidebar.shortTitle')"
      :title="$t('sidebar.title')"
    >
      <template  slot="links">
        <sidebar-item
          :link="{
            name: $t('sidebar.dashboard'),
            icon: 'tim-icons icon-chart-pie-36',
            path: '/dashboard'
          }"
        >
        </sidebar-item>

        <!-- <sidebar-item
          :link="{
            name: $t('sidebar.location'),
          icon: 'tim-icons icon-molecule-40',
            path: '/table-list/regular'
          }"
        >
 </sidebar-item> -->
 <!-- <div v-if="user.meter"> -->
<sidebar-item
          :link="{
            name: $t('sidebar.location'),
            icon: 'tim-icons icon-molecule-40',
            path:meters
            // '/meters'
          }"
        ></sidebar-item>
        <!-- <sidebar-item
          :link="{
            name: $t('sidebar.location'),
            icon: 'tim-icons icon-molecule-40',
            path:'/meters'
            // '/meters'
          }"
        ></sidebar-item> -->
 <!-- </div> -->

 <!-- <div v-else>
<sidebar-item
          :link="{
            name: $t('sidebar.location'),
            icon: 'tim-icons icon-molecule-40',
            path: '/table-list/regular'
          }"
        ></sidebar-item>
 </div> -->
  

<!-- <sidebar-item
          :link="{
            name: $t('sidebar.rank'),
            icon: 'tim-icons icon-trophy',
            path: '/rank'
          }"
        ></sidebar-item> -->

        

         <!-- <sidebar-item
         
          :link="{
            name: $t('sidebar.post'),
          icon: 'tim-icons  icon-align-center',
            path: '/posts/timeline'
          }"
        >

       
        </sidebar-item> -->

         <!-- <sidebar-item
          :link="{
            name: $t('sidebar.energy'),
          icon: 'tim-icons  icon-bulb-63',
            path: '/how'
          }"
        >

       
        </sidebar-item> -->

        <div v-if="admin">
<!-- <sidebar-item
          :link="{
            name: $t('sidebar.admin'),
          icon: 'tim-icons icon-components',
            path: 'admin'
          }"
        >
         </sidebar-item> -->
         

 <sidebar-item
          :link="{  name: $t('sidebar.admin'),
          icon: 'tim-icons icon-components' }"
        >

        <sidebar-item
            :link="{ name: $t('sidebar.list'), path: '/admin' }"
          ></sidebar-item>


          <sidebar-item
            :link="{
              name: $t('sidebar.map'),
              path: '/maps/full-screen'}"></sidebar-item>

<sidebar-item
            :link="{
              name: $t('menu.userreg'),
              path: '/invite-customers'}"></sidebar-item>


<hr> 





<sidebar-item
            :link="{
              name: $t('menu.manreg'),
              path: '/managers'}"></sidebar-item>
              <sidebar-item
            :link="{
              name: $t('menu.invitemanreg'),
              path: '/invite-managers'}"></sidebar-item>

<sidebar-item
            :link="{
              name: $t('menu.manper'),
              path: '/manager-permission'}"></sidebar-item>



<hr> 


<sidebar-item
            :link="{
              name: $t('menu.busreg'),
              path: '/invite-business-managers'}"></sidebar-item>



<sidebar-item
            :link="{
              name: $t('menu.busmanper'),
              path: '/business-manager-permission'}"></sidebar-item>




     
<hr> 

<sidebar-item
            :link="{
              name: $t('menu.build'),
              path: '/table-buildings'}"></sidebar-item>

<sidebar-item
            :link="{
              name: $t('menu.regbuild'),
              path: '/register-building'}"></sidebar-item>

<sidebar-item
            :link="{
              name: $t('menu.subbuild'),
              path: '/register-building-subdivision'}"></sidebar-item>












            </sidebar-item>

            



            
        
 </div>
<!--         
        <sidebar-item
          :link="{ name: $t('sidebar.pages'), icon: 'tim-icons icon-image-02' }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.pricing'), path: '/pricing' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.rtl'), path: '/pages/rtl' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.timeline'), path: '/pages/timeline' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.login'), path: '/login' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.register'), path: '/register' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.lock'), path: '/lock' }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.userProfile'), path: '/pages/user' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.components'),
            icon: 'tim-icons icon-molecule-40'
          }"
        >
          <sidebar-item :link="{ name: $t('sidebar.multiLevelCollapse') }">
            <sidebar-item
              :link="{
                name: $t('sidebar.example'),
                isRoute: false,
                path: 'https://google.com',
                target: '_blank'
              }"
            ></sidebar-item>
          </sidebar-item>

          <sidebar-item
            :link="{ name: $t('sidebar.buttons'), path: '/components/buttons' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.gridSystem'),
              path: '/components/grid-system'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.panels'), path: '/components/panels' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.sweetAlert'),
              path: '/components/sweet-alert'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.notifications'),
              path: '/components/notifications'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.icons'), path: '/components/icons' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.typography'),
              path: '/components/typography'
            }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{ name: $t('sidebar.forms'), icon: 'tim-icons icon-notes' }"
        >
          <sidebar-item
            :link="{ name: $t('sidebar.regularForms'), path: '/forms/regular' }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.extendedForms'),
              path: '/forms/extended'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.validationForms'),
              path: '/forms/validation'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{ name: $t('sidebar.wizard'), path: '/forms/wizard' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.tables'),
            icon: 'tim-icons icon-puzzle-10'
          }"
        >
          <sidebar-item
            :link="{
              name: $t('sidebar.regularTables'),
              path: '/table-list/regular'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.extendedTables'),
              path: '/table-list/extended'
            }"
          ></sidebar-item>
          <sidebar-item
            :link="{
              name: $t('sidebar.paginatedTables'),
              path: '/table-list/paginated'
            }"
          ></sidebar-item>
        </sidebar-item>
       
          <sidebar-item
            :link="{ name: $t('sidebar.vectorMaps'), path: '/maps/vector-map' }"
          ></sidebar-item>
        </sidebar-item>
        <sidebar-item
          :link="{
            name: $t('sidebar.widgets'),
            path: '/widgets'
          }"
        ></sidebar-item>
       
        <sidebar-item
          :link="{
            name: $t('sidebar.calendar'),
            icon: 'tim-icons icon-time-alarm',
            path: '/calendar'
          }"
        ></sidebar-item> -->
      </template>
    </side-bar>
    <!--Share plugin (for demo purposes). You can remove it if don't plan on using it-->
    <!-- <sidebar-share :background-color.sync="sidebarBackground"> </sidebar-share> -->
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
//import SidebarShare from './SidebarSharePlugin';
function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
//import DashboardContent from './Content.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import { ZoomCenterTransition } from 'vue2-transitions';

import supabase from '@/supabase.js'
//import config from '@/config';

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
   // DashboardContent,
   // SlideYDownTransition,
    ZoomCenterTransition,
    //SidebarShare
  },
  data() {
    return {
      sidebarBackground: 'vue', //vue|blue|orange|green|red|primary
      meters:'/meters',
      //,
      user:{},
      admin:false
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    initScrollbar() {
      let docClasses = document.body.classList;
      let isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('main-panel');
        initScrollbar('sidebar-wrapper');

        docClasses.add('perfect-scrollbar-on');
      } else {
        docClasses.add('perfect-scrollbar-off');
      }
    }
  },
  async created() {
//     const { data } = await supabase
//     .from('customers')
//   .select('id,name, Devices(id,device_code)')
//   .eq('id', this.$user.id)

//  // console.log(data);

//   this.$user.info=data;

 // this.user=data;
  },
  async mounted() {
    this.initScrollbar();

     this.user = this.$user;
     console.log(this.user);
     console.log("-------####------");

     if(this.user.user_metadata.userType.includes("business-manager")){
      this.admin=true;
     }
    

     const { data } = await supabase
  .from('customers')
  .select('id,name, user_devices(id,device_code)')
  .eq('id', this.user.id)

  console.log("nav devices:",data);

  this.user.info=data;


     if(this.user){
if(this.user.info[0].user_devices.length>1){
 //this.meters = '/table-list/regular';
 this.meters = '/listmeters';
 console.log('many');
}else{
this.meters ='/meters' ;
}
     }
  }
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
